<template>
  <div id="select">
    <div class="list-box">
      <div class="choose">
        <div class="item"> 
          <template v-if="$store.state.isMobile==false">
            产品分类：
            <el-select
            :size="$store.state.isMobile ? 'mini' : 'small'"
            v-model="choose"
            clearable
            placeholder="请选择分类"
            style="width:20%"
          >
            <el-option
              v-for="item in options"
              :key="item.cat_id"
              :label="item.name"
              :value="item.cat_id"
            >
            </el-option>
          </el-select>
          </template>
          

          <div class="keyword">
            <el-input class="inputwidth" :size="$store.state.isMobile ? 'mini' : 'small'" v-model="keyword" placeholder="请输入内容"></el-input>
             <el-button
             style="margin-left:10px"
            @click="search"
            type="primary"
            :size="$store.state.isMobile ? 'mini' : 'small'"
            icon="el-icon-search"
            >搜索</el-button
          >
            <el-select
            :size="$store.state.isMobile ? 'mini' : 'small'"
            v-model="isbendi"
           
            placeholder="请选类型"
            style="width:30%;margin-left:10px"
          >
            <el-option
              v-for="item in types"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          </div>

          
        </div>

         <div v-if='!$store.state.isMobile'>
          <el-button
            @click="kefu"
            type="primary"
            :size="$store.state.isMobile ? 'mini' : 'small'"
            icon="el-icon-s-goods"
            >我要供货</el-button
          >
        </div>

       

       
      </div>

      <div class="Selection" v-infinite-scroll="load">
        <el-row
          class="flex"
          :gutter="$store.state.isMobile ? 10 : 20"
          v-loading="loading"
        >
          <el-col
            :span="$store.state.isMobile ? 12 : 6"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="grid-content bg-purple item">
              <img v-lazy="item.pic1" ref='pic' :alt="item.title" />
              <div class="price">
                <span>¥{{ item.jiage }}</span>
                <span style='text-decoration:line-through;color:#ccc'>¥{{item.yuanjia}}</span>
                <el-tag type="danger" size="mini">{{item.zk}}折</el-tag>
              </div>
              <h6>{{ item.title }}</h6>
              <div class="shop">
                <i class="el-icon-s-shop"></i>
                <span>{{ item.shopname }}</span>
              </div>
              <div class="btn">

                <!-- ifcaiji -->

                 <el-button
                  v-if='item.ifcaiji==1'
                  type="danger"
                  disabled
                  icon="el-icon-sell"
                  :size="$store.state.isMobile ? 'mini' : 'small'"
                
                  >已铺货</el-button
                >
                
                <el-button
                  v-else
                  :type="item.jiajia>=$store.state.userinfo.zhekou?'danger':'info'"
                  @click="add(item.source,item.jiajia>=$store.state.userinfo.zhekou?true:false)"
                  icon="el-icon-sell"
                  :size="$store.state.isMobile ? 'mini' : 'small'"
                
                  >{{item.jiajia>=$store.state.userinfo.zhekou?'铺货':'无权限'}}</el-button
                >


                <el-button
                  @click="open(item.source)"
                  icon="el-icon-plus"
                  type="danger"
                  plain
                  :size="$store.state.isMobile ? 'mini' : 'small'"
                  >采购</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="back">
         <el-button type="primary" @click="back" v-show='searchStatus' round>返回列表</el-button>
      </div>

    </div>
  </div>
</template>
<style lang="css" >
@media screen and (max-width: 750px) {
  .el-select .el-input__inner {
    padding: 0 10px !important;
    width: 130px;
  }
  #select {
    height: 96%;
  }
  .inputwidth{
    flex: 1;
  }
  .keyword{
    padding-left: 0!important;
  }

}
.inputwidth{
  width: 40%;
}
.back{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.choose .item{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.choose .item .keyword{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0 14px;
}
</style>
<script>
export default {
  name: "Selection",
  data() {
    return {
      list: [],
      options: [],
      choose: "",
      page: 1,
      more: true,
      loading: true,
      keyword: "",
      searchStatus:false,
      isbendi:1,
      types:[
        {
          id:1,
          name:"线上售卖"
        },
         {
          id:2,
          name:"到店取货"
        }
      ]
    };
  },
  methods: {
    kefu() {
      this.$store.commit("changekefu");
    },
    back(){
      this.searchStatus=false;
      this.page=1;
       this.more=true;
       this.keyword='';
       this.getList();

    },
    getType() {
      this.axios.get(this.api.recommendChoose).then(res => {
        this.options = res;
      });
    },
    load() {
      this.page += 1;
      if (this.choose) {
        this.getList(this.choose);
      } else {
        this.getList();
      }
    },
    getList(fenlei = "") {
      if (this.more == true) {
        this.loading = true;
        let params={
             fenlei,
              page: this.page,
              shopid:this.$store.state.userinfo.shopid,
             
        }
        if(this.isbendi==2){
          params.isbendi=2
        }
        this.axios
          .get(this.api.recommend, {
            params,
          })
          .then(res => {
            this.loading = false;
            if (res.list == "") {
              this.more = false;

              if (this.page == 1) {
                this.$message({
                  message: "暂无内容",
                  type: "warning"
                });
              } else {
                this.$message({
                  message: "已经到底了",
                  type: "warning"
                });
              }
            } else {
              res.list.forEach((item)=>{
                item.zk=item.jiajia*10
               
              })
              this.list=[...this.list,...res.list]
              
            }
          });
      }
    },


    search(){
      
       if(this.keyword!=''){
          this.loading = true;
          this.page=1;
          this.axios
          .get(this.api.recommend, {
            params: {
              keyword:this.keyword
            }
          })
          .then(res => {
            this.loading = false;
            if (res.list == "") {
              this.more = false;
              if (this.page == 1) {
                this.$message({
                  message: "暂无内容",
                  type: "warning"
                });
              } else {
                this.$message({
                  message: "已经到底了",
                  type: "warning"
                });
              }
            } else {
              this.searchStatus=true;
              
               this.list=[];
               
                res.list.forEach((item)=>{
                item.zk=item.jiajia*10
              })
              this.list=[...res.list]
            }
          });
       }else{
          this.$message({
                  message: "请输入关键词",
                  type: "warning"
                });
       }
       
    },

    add(id,type) {
      if(type){
         this.$store.commit("addProductId", id);
         this.$store.commit("alertChange",true);
      }else{
        this.$message({
          message: '需要升级到更高级会员才可带货此产品',
          type: 'warning'
        });
      }
      
     
    },

    open(id) {
      // const _this = this;
      this.$alert(
        `<img style='width:200px;height:200px;display:block;margin:0 auto' src='/addons/yumi_dianqun/temp/ewm/${id}.png' >`,
        "采购",
        {
          dangerouslyUseHTMLString: true,
          showClose: false
          // beforeClose( done) {

          //   done();
          // }
        }
      );
    }
  },
  created() {
    if(this.$store.state.userinfo.fromshopname&&this.$store.state.userinfo.gzhucid){
      this.daili_id=this.$store.state.userinfo.gzhucid
    }
    this.getType();
    this.getList();
  },
  mounted() {
    //  pic

   
   
  },
  watch: {
    choose: function(id) {
      this.more = true;
      this.list = [];
      this.page = 1;
      this.getList(id);
    },
    isbendi:function(){
      this.more = true;
      this.list = [];
      this.page = 1;
      this.getList();
    },
    "$store.state.alert":function(state){
      if(!state.status){
        this.list=[];
        this.more=true;
         this.page = 1;
        this.getList()
      }
    },
  }
};
</script>